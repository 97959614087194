<template>
    <div>
        <table class="table-col table-cal month-inDay">
            <colgroup>
                <col span="7">
            </colgroup>
            <thead>
                <tr>
                    <th>SUN</th>
                    <th>MON</th>
                    <th>TUE</th>
                    <th>WED</th>
                    <th>THU</th>
                    <th>FRI</th>
                    <th>SAT</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(week, idx) in comp.calendar" :key="idx">
                    <td v-for="(day, idx_week) in week" :key="idx_week" @click.once="comp.selected({idx:'', cdate:day.cdate})" :class="{ 'today':comp.today==day.cdate }">
                        <span class="day" :class="{ 'holiday':'Y'==day.holiday }">{{day.day}} {{day.title}}</span>
                        <div class="inDay-txt" >
                            <template v-for="(node, idx_node) in comp.daysinfo" :key="idx_node">

                              <!-- 현장경영스케쥴 전용 Start-->
                              <div v-if="node.type=='schedule'" >
                                <button class="schedule-txt " :class="node.class" v-if="day.cdate==node.cdate " @click.stop.prevent="comp.selected(node)"  @mouseover="node.isShow=node.idx" @mouseleave="node.isShow=0">
                                  <span :class="{ 'sch1-color':node.class=='READY', 'sch2-color':node.class=='DONE', 'sch3-color':node.class=='VISIT' }">{{node.text}}</span>
                                </button>
                                <div class="calDetail-layer" v-if="node.isShow==node.idx && day.cdate==node.cdate">
                                  <ul class="calDetail-info">
                                    <li>{{ node.sub_text1 }}</li>
                                    <li>{{ node.sub_text2 }}</li>
                                    <li>{{ node.sub_text3 }}</li>
                                  </ul>
                                </div>
                              </div>
                              <!-- 현장경영스케쥴 전용 End-->

                              <button  class="day-txt" :class="node.class" v-if="day.cdate==node.cdate && node.type!='schedule'" @click.stop.prevent="comp.selected(node)"> {{node.text}}</button>
                            </template>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { onMounted, reactive, watch } from 'vue'
import axios from '@/plugins/axios.js'

export default {
    name: 'CarrotCalendar',
    components: {
    },
    props: {
        ymd: {
            type: [String],
            default: "",    // yyyy-mm-dd or yyyy-mm
        },
        daysinfo: {
            type: [String,Array],
            default: "",    // [ {"cdate" : "2021-06-01", "idx" : 3, "text" : "노출될 텍스트"} ]
        },
    },
    emits: [ 'close', 'selected' ],
    setup(props, { emit }) {

        const comp = reactive({
            calendar: [[]],
            daysinfo: [],
            today   : '',
            isShow:0,

            getCalenderInit: () => {
                let params = {ymd:props.ymd};

                axios.get('/rest/resourceManagement/calendarInit', { params : params }).then((res) => {
                    // console.info(res.data);
                    if( res.data.err == 0 ){
                        // console.info(res.data.calendar);
                        comp.calendar = res.data.calendar;
                    } else {
                        console.info(res.data.err_msg);
                    }
                })
            },

            selected: (node) => {
                emit("selected", node)
            },

            close: () => {
                emit('close');
            },
        });

        watch(() => props.ymd, (value, preValue) => {
            if(preValue!=value) {
                comp.getCalenderInit();
            }
        });

        watch(() => props.daysinfo, (value, preValue) => {
            if(preValue!=value) {
                comp.daysinfo = props.daysinfo;
            }
        });

        onMounted(() => {
            // Mounted
            if(props.daysinfo=="") {
                comp.daysinfo = [];
                // comp.daysinfo = [ {"cdate" : "2021-06-03", "idx" : 3, "text" : "1호차 - 전병준"} ];
            } else {
                comp.daysinfo = props.daysinfo;
            }

            let nd = new Date();
            let today = nd.getFullYear()
                    + '-' + (nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1))
                    + '-' + (nd.getDate()>9?nd.getDate():'0'+nd.getDate());
            comp.today = today;

            comp.getCalenderInit();
        });

        return {comp};
    }
}
</script>
<style lang="scss" scoped>
.sub-wrap {
    .con-wrap {
        .table-col.table-cal.month-inDay {
            tbody {
                tr {
                    td {
                        padding:13px 10px; cursor:pointer;
                        .holiday {
                          color: red;
                        }
                        .inDay-txt {
                            .day-txt {
                                display:inline-block;
                                width:100%;
                                padding:3px 5px 2px;
                                background-color:#ff854a;
                                color:#fff;
                                border-radius:4px;
                                font-size:12px;
                                text-align:left;
                                margin-top: 3px;
                                &:first-child {
                                    margin-top: 0px;
                                }
                                &.vacation {
                                    background-color:#5d9cec;
                                    &:hover {
                                        background-color: #498adf;
                                    }
                                }
                            }

                            .calDetail-layer {
                              position:absolute;width:220px;  margin-left:20px;  display:block; z-index:2; background-color:#f9f9f9; box-shadow: inherit;
                            }

                            .schedule-txt {
                              border: 0;
                              background-color:transparent;
                              display:inline-block;
                              padding:3px 5px 2px;
                           //   color:#fff;
                              border-radius:4px;
                              font-size:12px;
                              text-align:left;
                              margin-top: 3px;
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              width:100%;

                              .sch1-color {
                                color: #555555;
                              }

                              .sch2-color {
                                color: #5d9cec;
                              }

                              .sch3-color {
                                color: #ff854a;
                              }
                              &:first-child {
                                margin-top: 0px;
                              }
                              &.vacation {
                                background-color:#5d9cec;
                                &:hover {
                                  background-color: #498adf;
                                }
                              }
                            }
                          .reserve-bg-in{
                           background-color:rgba(5,171,25,0.6);
                          }
                          .reserve-bg-out{
                           background-color:rgba(255,118,0,0.6);
                          }
                          .reserve-bg-owner{
                            background-color:rgba(0,114,255,0.6);
                          }

                        }
                        &.today {
                            background-color: rgba(73, 223, 148, 0.1);
                        }


                    }
                }
            }
        }
    }
}

</style>