<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <ClassroomReservationViewPopup :idx="pop.idx" :tdate="pop.tdate" v-if="pop.isShowSchedule" @close="pop.hideSchedule"></ClassroomReservationViewPopup>
        <ClassroomSitePopup v-if="pop.isShowClassroomSite" @close="pop.isShowClassroomSite=false"></ClassroomSitePopup>
        <ClassroomRentalcostPopup v-if="pop.isShowClassroomCost" @close="pop.isShowClassroomCost=false"></ClassroomRentalcostPopup>

        <CarrotTitle title="강의장 예약하기">
            <div class="btn-ibbox title-btnbox float-right">
                <button @click="pop.isShowClassroomSite=true" class="btn-default">강의실 지도</button>
                <button @click="pop.isShowClassroomCost=true" class="btn-default ml-20">강의실 대여요금</button>
                <button @click="bbs.showAnalysis" class="btn-default ml-20">통계</button>
            </div>
            - 예약현황을 클릭하시면 상세 내용을 보실 수 있습니다.<br>
            - 본인이 예약한 현황은 하늘색으로 표기되며, 이미 예약된 현황은 내부는 연녹색, 고객용은 주황색으로 표기됩니다.
            <div class="room-info">
              <div class="clear room-color mr-10">
                <div style="background-color:rgba(255,118,0,0.6);"></div> <p>&nbsp;- 고객용</p>
              </div>
              <div class="clear room-color mr-10">
                <div style="background-color:rgba(5,171,25,0.6);"></div> <p>&nbsp;- 내부용</p>
              </div>
              <div class="clear room-color">
                <div style="background-color:rgba(0,114,255,0.6);"></div> <p>&nbsp;- 본인</p>
              </div>
            </div>
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <div class="day-select mt-40">
                        <button @click="bbs.doPrev" class="btn btn-prev">prev</button>

                      <button @click="bbs.showCalendar" class="day btn-cal">
                        {{ bbs.today.substring(0, 7) }}
                        <v-datepicker v-model="bbs.tdate" @dayclick="bbs.doCalculate" class="datepicker-group">
                          <template #default="{ inputValue, togglePopover }">
                            <div>
                              <input type="text" :value="inputValue" style="display:none;" />
                              <button class="btn-month" @click="togglePopover()" style="display:none;"></button>
                            </div>
                          </template>
                        </v-datepicker>
                      </button>
                        <button @click="bbs.doNext" class="btn btn-next">next</button>
                    </div>

                    <div class="dp-table w-100per mt-40 mb-20">
                      <button @click="bbs.showDays" class="btn-default float-left w-70px">일별</button>
                      <button @click="bbs.showWeeks" class="btn-default float-left w-70px">주별</button>
                      <button @click="bbs.showMonths" class="btn-default float-left w-70px">월별</button>
                      <button @click="bbs.addSchedule('')" class="btn-default float-right">강의실 예약하기</button>
                    </div>

                  <CarrotCalendar :daysinfo="bbs.list" :ymd="bbs.today" @selected="bbs.doSelect"></CarrotCalendar>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import ClassroomReservationViewPopup from '@/components/popup/ResourceManagement/ClassroomReservationViewPopup.vue'
import ClassroomSitePopup from '@/components/popup/ResourceManagement/ClassroomSitePopup.vue'
import ClassroomRentalcostPopup from '@/components/popup/ResourceManagement/ClassroomRentalcostPopup.vue'
import CarrotCalendar from '@/components/common/CarrotCalendar.vue'
import {DatePicker} from "v-calendar";

export default {
    layout: 'ResourceManagement',
    components: {
        'v-datepicker' : DatePicker,
        ClassroomReservationViewPopup,
        ClassroomSitePopup,
        ClassroomRentalcostPopup,
        CarrotCalendar
    },
    setup() {
        const router = useRouter();
        const toast = useToast()

        const pop = reactive({
            idx   : 0,
            tdate : "",
            // Popup 노출 여부
            isShowClassroomSite : false,
            isShowClassroomCost : false,
            isShowSchedule      : false,

            showSchedule : (idx) => {
                sessionStorage.setItem('ClassroomReservationDate', JSON.stringify({
                    today : bbs.today
                }));
                pop.idx   = idx;
                pop.tdate = bbs.today;
                pop.isShowSchedule = true;
                console.log(pop);
            },
            hideSchedule : () => {
                pop.isShowSchedule = false;
                pop.idx = 0;
                bbs.doSearch();
            },
        });


        const bbs = reactive({
            colSize     : 0,
            date_labels : [],
            time_labels : [],
            time_count  : 0,
            stype : "monthly",
            today : "",

            list : [], total : 0,

            doPrev : () => {
              let td = new Date(bbs.today);
              let nd = new Date(td.getFullYear(), td.getMonth()-1, td.getDate());
              bbs.today = [nd.getFullYear(), nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1), nd.getDate()>9?nd.getDate():'0'+nd.getDate()].join('-');
              bbs.doSearch();
            },

            doNext : () => {
              let td = new Date(bbs.today);
              let nd = new Date(td.getFullYear(), td.getMonth()+1, td.getDate());
              bbs.today = [nd.getFullYear(), nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1), nd.getDate()>9?nd.getDate():'0'+nd.getDate()].join('-');
              bbs.doSearch();

              sessionStorage.setItem('ClassroomReservationMonth', JSON.stringify({
                today : bbs.today
              }));
            },
            doSelect: (o) => {
              if( Number(o.idx) > 0 ){
                pop.showSchedule(o.idx);
              } else {
                console.log(o.cdate);
                bbs.addSchedule(o.cdate);
              }
            },
            showAnalysis : () => {
                sessionStorage.setItem('ClassroomReservationDate', JSON.stringify({
                    today : bbs.today
                }));
                router.push({
                    name : 'ResourceManagement-ClassroomReservationStatistics'
                })
            },

            showDays : () => {
              sessionStorage.setItem('ClassroomReservationDate', JSON.stringify({
                today : bbs.today
              }));
              router.push({
                name : 'ResourceManagement-ClassroomReservationDate'
              });
            },
            showMonths : () => {
              sessionStorage.setItem('ClassroomReservationDate', JSON.stringify({
                today : bbs.today
              }));
              router.push({
                name : 'ResourceManagement-ClassroomReservationMonth'
              });
            },
            showWeeks : () => {
              sessionStorage.setItem('ClassroomReservationDate', JSON.stringify({
                today : bbs.today
              }));
              router.push({
                name : 'ResourceManagement-ClassroomReservationWeek'
              });
            },

            addSchedule : (cdate) => {


                if( !cdate ){
                  sessionStorage.removeItem('ClassroomReservationAdd');
                } else {
                  sessionStorage.setItem('ClassroomReservationAdd', JSON.stringify({
                    today : cdate
                  }));
                }
                router.push({
                    name : 'ResourceManagement-ClassroomReservationAdd'
                });
            },

            makeTimeSchedules : () => {
                let nd = new Date(bbs.today);
                let td = new Date(nd.getFullYear(), nd.getMonth(), nd.getDate(), 7, 0, 0);

                for(let i=0; i<bbs.total; i++){
                    let irow = bbs.list[i];

                    for(let j=0; j<irow.total; j++){
                        let jrow = irow.list[j];

                        jrow.top    = 0;
                        jrow.height = 0;

                        let s = 0;
                        for(let k=td.getTime(), l=0; k<td.getTime()+(3600*1000*15); k+=1800*1000, l++){
                            let kd = new Date(k);

                            let ktime = (kd.getHours()<10?'0'+kd.getHours():kd.getHours())
                                      + ':' + (kd.getMinutes()<10?'0'+kd.getMinutes():kd.getMinutes());

                            if( jrow.stime == ktime ){
                                s = l;
                            }
                            if( jrow.stime <= ktime && ktime < jrow.etime ){
                                jrow.height += 30;
                            }

                        }

                        jrow.top = s*30;

                        irow.list[j] = jrow;
                    }
                }
            },

            doSearch : () => {
                let params = {
                  tgmonth : bbs.today
                };
                axios.get('/rest/resourceManagement/getClassroomReservationMonth', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.groupList = res.data.group_list;

                        bbs.list  = res.data.list;
                        bbs.total = bbs.list.length;

                        bbs.colSize = 1154/bbs.total;

                        bbs.makeTimeSchedules();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

            makeTimeLabels : () => {
                for(let i=7; i<23; i++){
                    if( i < 10 ){
                        bbs.time_labels.push( '0' + i + ':00');
                        bbs.time_labels.push( '0' + i + ':30');
                    } else if ( i == 22 ){
                        bbs.time_labels.push( i + ':00' );
                    } else {
                        bbs.time_labels.push( i + ':00');
                        bbs.time_labels.push( i + ':30');
                    }
                }

                bbs.time_count = bbs.time_labels.length - 1;
            },

        });

        onMounted(() => {
            // Mounted
            let nd = new Date();
            let today = nd.getFullYear()
                    + '-' + (nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1))
                    + '-' + (nd.getDate()>9?nd.getDate():'0'+nd.getDate());
            bbs.today = today;

            bbs.makeTimeLabels();

            bbs.doSearch();
        });

        return {pop, bbs};
    }
}
</script>

<style lang="scss" scoped>
.room-info{
  .room-color{
    display: inline-block;
    p{
      float: left;
    }
    div{
      width: 18px;
      height: 18px;
      float: left;
      margin-left: 5px;
      margin-top: 3px;
    }
  }
}
.dayTable-box { 
    width:1200px;

    .day-time {
        position:relative;

        p {
            position:relative; margin-top:10px; margin-right:5px; height:20px;z-index:2; text-align:right;
            &:first-child {
                margin-top:58px;
            }
        }
        .group-title {
            position:absolute;top:0;left:0;margin:0; width:100%; height:70px;font-size:14; font-weight:bold;color:#555;border-right:solid 1px #ddd;line-height:70px;text-align:center;background-color:#f9f9f9; z-index:0;
        }
    }

    .div-dayTable{
        position:relative;

        &.group-label {
            .tr .cell {
                height:40px;
            }
        }
        &.middle-label {
            border-top: solid 1px #ddd; margin-top:-1px;
            .thead {
                .tr {
                    .th {
                        border-bottom: solid 2px #616161;
                        &:nth-child(8) {
                            border-left: 2px solid #616161;
                        }
                        &:nth-child(12) {
                            border-left: 2px solid #616161;
                        }
                        &:nth-child(15) {
                          border-left: 2px solid #616161;
                        }
                        &:nth-child(19) {
                          border-left: 2px solid #616161;
                        }
                    }
                }
            }
        }

        .tr {
          .active{
            background-color: #D8D8D8
          }
            .cell {
                height:30px;
                &.td{
                  &:nth-child(8) {
                        border-left: 2px solid #616161;
                    }
                    &:nth-child(12) {
                        border-left: 2px solid #616161;
                    }
                    &:nth-child(15) {
                        border-left: 2px solid #616161;
                    }
                    &:nth-child(19) {
                        border-left: 2px solid #616161;
                    }
                }
                &.th{
                    // &:nth-child(2) {
                    //     border-left: 2px solid #616161;
                    // }
                    // &:nth-child(3) {
                    //     border-left: 2px solid #616161;
                    // }
                    // &:nth-child(4) {
                    //     border-left: 2px solid #616161;
                    // }
                    // &:nth-child(5) {
                    //     border-left: 2px solid #616161;
                    // }
                    // &:nth-child(5) {
                    //     border-left: 2px solid #616161;
                    // }
                    // &:nth-child(6) {
                    //     border-left: 2px solid #616161;
                    // }
                }
            }
        }

        .reserve-body {
            position:absolute; top:30px; left:0; width:100%;
            .reserve-cell {
                position: relative; height:1px; float:left;

                .reserve-bg {
                    width:100%; background-color:rgba(0,0,0,0.01); position: absolute;
                }
                .reserve-bg-in {
                  width:100%; background-color:rgba(255,255,0,0.6); position: absolute;
                }
                .reserve-bg-owner {
                  width:100%; background-color:blue; position: absolute;
                }
                .btn-reserve {
                    border: 0;background-color: rgba(255,133,74,0.6);width:100%;height:100%;z-index: 1;

                    &:hover {
                        background-color: rgba(255,133,74,1);
                    }
                    &.owner {
                        background-color:rgba(93,156,236,0.6);
                        &:hover {
                            background-color:rgba(93,156,236,1);
                        }
                    }
                }
            }
        }
    }
}
</style>